import gql from 'graphql-tag';

import { prettifyNumber } from 'common/helpers/helpers';

// TYPES

export type THomepageData$ProvidersCount = {
  registered: number;
  online: number;
};

export type PrettifiedCounts = {
  [key in keyof THomepageData$ProvidersCount]: string;
};

export type THomepageData$FeaturedProvider$LastFeedback = {
  title: string;
  text: string;
  rating: number;
};

export type THomepageData$FeaturedProvider = {
  id: string;
  avatarUrl: string;
  firstName: string;
  cityName: string;
  rating: number;
  feedbackCount: number;
  lastFeedback?: THomepageData$FeaturedProvider$LastFeedback;
};

export type THomepageData$PopularService = {
  id: string;
  slug: string;
  name: string;
  coverUrl: string;
  requestsCount: number;
};

export type THomepageData = {
  providersCounts: THomepageData$ProvidersCount;
  featuredProviders: THomepageData$FeaturedProvider[];
  popularServices: THomepageData$PopularService[];
};

export type TBlogPost = {
  id: string;
  title: string;
  description: string;
  link: string;
  createdAt: string;
  img: string;
};

export type THomepageQueryResponse = {
  homepage: THomepageData;
  blog: TBlogPost[];
};

// HELPERS

export const homepageQuery = gql`
  query HomepageQuery {
    homepage {
      providersCounts {
        registered
        online
      }
      featuredProviders(limit: 40) {
        id
        avatarUrl
        firstName
        cityName
        rating
        feedbackCount
        lastFeedback {
          title
          text
          rating
        }
      }
      popularServices {
        id
        slug
        name
        coverUrl
        requestsCount
      }
    }
    blog(type: user) {
      id
      link
      title
      description
      createdAt
      img
    }
  }
`;

export const prettifyProviderCounts = (counts: THomepageData$ProvidersCount): PrettifiedCounts =>
  Object.keys(counts).reduce(
    (acc, key) => ({ ...acc, [key]: prettifyNumber(counts[key as keyof THomepageData$ProvidersCount]) }),
    { registered: '', online: '' }
  );
