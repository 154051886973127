import { useQuery } from '@apollo/react-hooks';
import { t, Trans } from '@lingui/macro';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import AdminMessageController from 'Components/AdminMessage/AdminMessageController';
import AndroidBanner from 'Components/AndroidBanner/AndroidBanner';
import ArticleList from 'Components/ArticleList/ArticleList';
import CategoriesSlider, { CategorySlide } from 'Components/CategoriesSlider/CategoriesSlider';
import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import ProtectedContent from 'Components/ProtectedContent/ProtectedContent';
import PageView from 'common/PageView/PageView';
import config from 'config/config';
import routes from 'utils/routeTranslator';

import ClientAppBanner from './ClientAppBanner/ClientAppBanner';
import { THomepageData$PopularService, homepageQuery, THomepageQueryResponse } from './Homepage.helpers';
import './Homepage.scss';
import HomepageBanners from './HomepageBanners/HomepageBanners';
import HomepageHeader from './HomepageHeader/HomepageHeader';
import ServiceValues from './ServiceValues/ServiceValues';

const mapCategoriesToSlides = (popularServices: THomepageData$PopularService[]): CategorySlide[] => {
  return popularServices.map(({ coverUrl, ...rest }) => ({
    ...rest,
    imageUrl: coverUrl,
  }));
};

const Homepage: React.FC = () => {
  const { loading, data } = useQuery<THomepageQueryResponse>(homepageQuery);
  const { currentUser } = useCurrentUser();
  if (loading || !data) return null;

  const { featuredProviders, providersCounts, popularServices } = data.homepage;

  const siteTitle = t`Fixly.pl - wybierz najlepszego spośród ${providersCounts.registered} wykonawców!`;
  // eslint-disable-next-line max-len
  const siteDescription = t`Daj znać, jakiej usługi potrzebujesz, porównaj profile, sprawdź opinie i wybierz wykonawcę. Fixly od OLX!`;

  return (
    <ProtectedContent providerRedirect={routes.get('front::dashboard')}>
      <div className="homepage">
        <AndroidBanner />
        <Helmet>
          <title>{siteTitle}</title>
          <meta property="og:description" content={siteDescription} />
          <meta name="description" content={siteDescription} />
          <link rel="canonical" href={config.SITE_URL + routes.get('front::index')} />
        </Helmet>
        <PageView pageLabel="home" />

        <HomepageBanners className="homepage__homepageBanners" />
        <section className="homepage__header">
          <HomepageHeader counts={providersCounts} featuredProviders={featuredProviders} />
        </section>

        <section className="homepage__popularCategories">
          <h2 className="homepage__subtitle homepage__subtitle_popularCategories">
            <Trans>Najpopularniejsze usługi na Fixly</Trans>
          </h2>
          <CategoriesSlider categories={mapCategoriesToSlides(popularServices)} shouldUseNativeNavigation isHomepage />
        </section>

        <section className="homepage__serviceValues">
          <ServiceValues />
        </section>

        <section className="homepage__clientAppBanner">
          <ClientAppBanner />
        </section>

        <section className="homepage__blog">
          <h2 className="homepage__subtitle homepage__subtitle_blog">
            <Trans>Najnowsze wpisy na naszym blogu</Trans>
          </h2>
          <ArticleList items={data.blog} className="homepage__blogCards" />
        </section>
        {currentUser && <AdminMessageController />}
      </div>
    </ProtectedContent>
  );
};

export default Homepage;
